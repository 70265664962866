/* faq's css */

.faq-single{
  margin-bottom: 40px;
  h6{
    margin-bottom: remy(10px);
  }
  p{
    margin: 0;
  }
}