//this file list of components/scss partial along with the style.scss contents order.
// This file is included at the very top of the
// style.scss
/**                              **
**       TABLE OF CONTENTS       **
**                               **
-----------------------------------*/
/*
  01.COMMON
    -- reset
    -- helper
    -- theme
    -- general
    -- mixins

  02.COMPONENTS
    -- menu
    -- sidebar
    -- btn
    -- product
    -- social
    -- accordion
    -- breadcrumb
    -- modal
    -- table
    -- progressbar
    -- timeline
    -- team
    -- pricing
    -- datepicker
  03. MENU AREA
  04. HERO AREA
  05. FEATURE AREA
  06. PRODUCTS AREA
  07. FEATURED PRODUCTS AREA
  08. FOLLOWERS FEED
  09. COUNTER UP AREA
  10. WHY CHOOSE AREA
  11. SELL BUY AREA
  12. TESTIMONIAL AREA
  13. LATEST NEWS AREA
  14. SPECIAL FEATURE
  15. CALL TO ACTION
  16. FOOTER AREA
  17. PROMOTION AREA
  18. OVERVIEW AREA
  19. PARTNER AREA
  20. SEARCH AREA2
  21. SINGLE PRODUCT1 AREA
  22. OVERVIEW AREA
  23. AUTHOR AREA
  24. DASHBOARD AREA
  25. NOTIFICATIONS AREA
  26. MESSAGE AREA
  27. CART PAGE AREA
  28. CHECKOUT AREA
  29. SUPPORT AREA
  30. HOW IT WORKS AREA
  31. FORUM AREA
  32. ABOUT US  HERO AREA
  33. ABOUT AND MISSION AREA
  34. GALLERY ARE
  35. TESTIMONIAL VIDEO
  36. PRICING AREA
  37. FAQ AREA
  38. AFFILIATE PAGE
  39. TERM AND CONDITION AREA
  40. BLOG AREA
  41. CONTACT AREA
  42. 404 AREA
  43. JOB HERO HERO
  44. JOB  AREA
  45. FEATURE AREA
  46. JOB DETAIL
  47. DETAIL AREA
  48. EVENT AREA
  49. EVENT DETAIL
  50. MAP AREA
  51. TICKET AREA
  52. RESPONSIVE CSS
***************************/