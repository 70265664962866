@import "../../../vendor_assets/css/bootstrap/config.bs";
@import "shadow";
@import "top-bar";
@import "menu";
@import "modal";
@import "breadcrumb";
@import "footer";
@import "pricing";
@import "timeline";
@import "counter";
@import "shadow";
@import "carousel";
@import "clients";
@import "dropdowns";
@import "gallery";
@import "range-slider";
@import "videos";
@import "parallax";
@import "splitted-banner";
@import "subscribe";
@import "twitter-feed";
@import "call-to-action";
@import "processes";
@import "team";
@import "faqs";
@import "sidebar";
@import "widgets";

@import "address";
@import "map";
@import "footer";
