/* list styles */
ul{
  margin: 0;
}
.list-item {
  display: flex;
  align-items: flex-start;
}
/* arrow lists */
.arrow-list1{
  font-weight: 500;
  margin-bottom: 0.45rem;
  vertical-align: middle;
  &:last-child{
    margin-bottom: 0;
  }
  &:before {
    content: "\f121";
    font-family: "Lineawesome", sans-serif;
    margin-right: 10px;
  }
}
.arrow-list2, .arrow-list3, .arrow-list5{
  margin-bottom: remy(12px);
  &:last-child{
    margin-bottom: 0;
  }
  span{
    width: remy(21px);
    height: remy(21px);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: remy(10px);
    margin-right: 0.86rem;
    color: $primary;
  }
}
.arrow-list2 span, .arrow-list5 span{
  background: rgba($primary, 0.1);
}
.arrow-list3 span{
  background: $primary;
  color: $light;
}
.arrow-list4{
  margin-bottom: 0.66rem;
  position: relative;
  padding-left: 18px;
  &:before{
    position: absolute;
    content: '\f186';
    font-family: "LineAwesome";
    left:0;
    top:2px;
    font-size: remy(12px);
  }
  &:last-child{
    margin-bottom: 0;
  }
  span{
    color: $light-gray;
    padding-right: remy(5px);
  }
}
.arrow-list5{
  font-weight: 500;
}

//content lists
.content-list{
  margin-bottom: remy(40px);
  &:last-child{
    margin-bottom: 0;
  }
  .icon{
    margin-right: 1rem;
    font-size: 2rem;
    line-height: 1;
  }
  h6{
    margin-bottom: remy(7px);
  }
  p{
    margin-bottom: 0;
  }
  .icon-circle{
    margin-right: 2rem;
    span{
      width: remy(60px);
      height: remy(60px);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f4f7;
      font-size: 1.73rem;
      transition: $transition-base;
    }
  }
  &:hover{
    .icon-circle{
      span{
        background: $secondary;
        color: $light;
      }
    }
  }
}
.icon-light{
  margin-bottom: 0;
  .content-list{
    .icon-circle{
      span{
        @extend .bg-secondary;
        color: #fff;
      }
    }
    &:hover{
      .icon-circle{
        span{
          background: $secondary;
          color: $light;
        }
      }
    }
  }
}

// text list
.text-list{
  margin-bottom: 2rem;
  &:last-child{
    margin-bottom: 0;
  }
  h5, h6{
    color: $dark;
    margin-bottom: 1.2rem;
  }
  p{
    margin-bottom: 0;
  }
  .sub-text{
    font-size: remy(18px);
    font-weight: 400;
    display: block;
    margin-bottom: 0.66rem;
  }
}

// image list
.image-list{
  margin-bottom: remy(35px);
  &:last-child{
    margin-bottom: 0;
  }
  .img{
    margin-right: 1.66rem;
    img{
      border-radius: 50%;
    }
  }
  h6{
    margin-bottom: 0.66rem;
    color: $dark;
  }
  .sub-text{
    margin-bottom: 1rem;
    display: block;
    color: $light-gray;
  }
  p{
    margin: 0;
  }
}

// bullet list
.bullet_list{
  position: relative;
  padding-left: 1.66rem;
  margin-bottom: 0.66rem;
  &:last-child{
    margin-bottom: 0;
  }
  &:before{
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    @extend .bg-secondary;
    border-radius: 50%;
    left: 0;
    top: 6px;
  }
  strong{
    font-weight: 500;
  }
  p{
    margin: 0;
  }
  &.bullet_list--three{
    margin-bottom: remy(25px);
    &:last-child{
      margin-bottom: 0;
    }
  }
}

//check list
.checklist{
  margin-bottom: remy(10px);
  display: flex;
  align-items: center;
  &:last-child{
    margin-bottom: 0;
  }
  span{
    font-size: remy(15px);
    font-weight: 700;
    margin-right: 0.93rem;
  }
  &.checklist-light{
    color: rgba($light, 1);
    display: flex;
    align-items: center;
    .svg{
      width: remy(18px);
      fill: rgba($light, 1);
    }
    span{
      font-weight: normal;
      margin-left: remy(12px);
    }
    &.color-dark{
      .svg{
        fill: $dark;
      }
    }
  }
  .svg{
    margin-right: 8px;
    width: remy(12px);
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 12px;
    }
  }
  &.icon-primary{
    .svg .st0{
      fill:$primary;
    }
  }
  &.icon-secondary{
    .svg .st0{
      fill:$secondary;
    }
  }
  &.icon-light{
    .svg .st0{
      fill:$light;
    }
  }
  &.icon-dark{
    .svg .st0{
      fill:$dark;
    }
  }
}
.checklist-three{
  display: flex;
  align-items: center;
  margin-bottom: remy(10px);
  font-weight: 500;
  &:last-child{
    margin-bottom: 0;
  }
  .svg{
    width: remy(12px);
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: 12px;
    }
    .st0{
      fill:$secondary;
    }
  }
  span{
    margin-left: remy(10px);
  }
  &.checklist-light{
    span{
      color: #fff;
      font-weight: normal;
    }
    .svg{
      .st0{
        fill: $light;
      }
    }
  }
}

//icon lists
.icon-list{
  margin-bottom: 1rem;
  &:last-child{
    margin-bottom: 0;
  }
  span{
    font-size: 1.33rem;
    padding-right: 0.66rem;
    line-height: 0;
  }
  strong{
    font-weight: 500;
    color: $dark;
    margin-right: remy(15px);
  }
  &.list-light{
    color: rgba($light, 0.5);
    strong{
      color: $light;
    }
  }
}
.icon-list2{
  margin-bottom: 2rem;
  &:last-child{
    margin-bottom: 0;
  }
  .icon{
    font-size: 1.8rem;
    @extend .color-secondary;
    line-height: 1;
    margin-right: 1rem;
  }
  h6{
    margin-bottom: 0.6rem;
    color: $dark;
    font-weight: 500;
    font-size: remy(17px);
  }
  .sub-text{
    color: $light-gray;
  }
  &.list-light{
    h6{
      color: $light;
    }
  }
}
.icon-list--two{
  .icon-list{
    display: flex;
  }
}

//number list
.number-list{
  list-style: none;
  counter-reset: li;
  padding: 0;
  li{
    counter-increment: li;
    margin-bottom: 0.66rem;
    position: relative;
    padding-left: remy(30px);
    &:last-child{
      margin-bottom: 0;
    }
    &:before{
      content:counter(li);
      @extend .color-secondary;
      display: inline-block;
      width: 1em;
      text-align: right;
      direction: rtl;
      position: absolute;
      left: 0;
      top: 0;
      font-weight: 500;
     
     }
    &.numbers-decimal-zero{
      &:before{
        content: counter(li, decimal-leading-zero);
        color: $dark;
      }
      &.list-light{
        &:before{
          color: $light;
        }
      }
    }
    &.numbers-roman{
      &:before{
        content: counter(li, lower-roman);
        @extend .color-secondary;
      }
    }
    &.list-light{
      color: rgba($light, 0.7);
    }
  }
}

//process list
.process-list{
  margin: 0;
  li{
    margin-bottom: remy(30px);
    &:last-child{
      margin-bottom: 0;
    }
    &:last-child .number span:before{
      content: none;
    }
    &:hover{
      .number{
        span{
          background: $secondary;
          color: $light;
        }
      }
    }
  }
  .number{
    margin-right: remy(30px);
    &.gray-bg{
      span{
        background: rgba($secondary, 0.1);
      }
    }
    span{
      width: remy(68px);
      height: remy(68px);
      background: $light;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: remy(24px);
      font-weight: 600;
      color: $secondary;
      position: relative;
      transition: $transition-base;
    }
  }
  .content{
    h6{
      margin-bottom: remy(10px);
      color: $dark;
      font-weight: 600;
    }
    p{
      margin: 0;
    }
  }
}

//list inline
.list-inline-wrapper{
  box-shadow: $box-shadow-bg-white;
  .list--inline{
    margin: 0;
    justify-content: space-between;
    flex-wrap: wrap;
    li{
      margin-bottom: remy(30px);
      flex: 1;
      .icon{
        margin-right: remy(15px);
        span{
          font-size: remy(50px);
          display: block;
          line-height: remy(30px);
          @extend .color-secondary;
        }
      }
      .contents{
        h6{
          font-weight: 600;
          color: $dark;
          margin: 0 0 5px;
        }
      }
    }
  }
}

//list count
.list-count{
  margin-bottom: remy(15px);
  &:last-child{
    margin-bottom: 0;
  }
  span{
    color: $dark;
    transition: $transition-base;
  }
  .count{
    min-width: remy(45px);
    height: remy(25px);
    border-radius: remy(25px);
    background: #F5F7FC;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transition-base;
  }
  a:hover{
    span{
      color: $primary;
    }
    .count{
      color: $light;
      background: $primary;
    }
  }
}












