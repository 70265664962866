
.biz_overlay{
  position: relative;
  height: 100%;
  width: 100%;
  &:before{
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    content: '';
    z-index: 1;
    background: rgba(#000, .6);
  }
  &.overlay--primary{
    @include overlay(theme-color('primary'), .85);
  }
  &.overlay--secondary{
    @include overlay(theme-color('secondary'), .80);
  }
  &.overlay--secondary2{
    &:before{
      background: rgba(#1b1c39, 0.8);
    }
  }
  &.overlay--dark{
    @include overlay(theme-color('dark'), .6);
  }
  &.overlay--dark2{
    &:before{
      background: rgba(#1d1e33, 0.7);
    }
  }
}
.gradient-overlay{
  position: relative;
  &:before{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top:0;
    background:linear-gradient(140deg, rgba(65,58,164,0.9) 0%, rgba(65,58,164,0.9) 25%, rgba(6,181,144,0.9) 100%);
    z-index: 1;
  }
}
